import type {
  DateOperand,
  IdsOperand,
  ManagersOperand,
  SelectIdsOperand,
  SelectOperand,
  TextOperand,
} from '@blissbook/ui/application/graph'
import isDate from 'lodash/isDate'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import {
  type DateOperandCondition,
  OperandType,
  TextOperandCondition,
} from './types'

// DateOperandValue -----------------------------------------------------------

export type DateOperandValue = Omit<DateOperand, 'type'> & {
  type: OperandType.Date
}

export function isDateConditionRelative(condition: DateOperandCondition) {
  switch (condition) {
    case 'moreThanDaysAgo':
    case 'moreThanOrEqualToDaysAgo':
    case 'exactlyDaysAgo':
    case 'lessThanDaysAgo':
    case 'lessThanOrEqualToDaysAgo':
    case 'moreThanDaysFromNow':
    case 'moreThanOrEqualToDaysFromNow':
    case 'exactlyDaysFromNow':
    case 'lessThanDaysFromNow':
    case 'lessThanOrEqualToDaysFromNow':
      return true
    default:
      return false
  }
}

export function isDateConditionAbsolute(condition: DateOperandCondition) {
  switch (condition) {
    case 'afterDate':
    case 'onDate':
    case 'beforeDate':
    case 'isOnOrAfter':
    case 'isOnOrBefore':
      return true
    default:
      return false
  }
}

export function isDateOperandValueValid(value: DateOperandValue) {
  if (isDateConditionRelative(value.dateCondition)) {
    return isNumber(value.days)
  }

  if (isDateConditionAbsolute(value.dateCondition)) {
    return isDate(value.date) || isString(value.date)
  }

  return true
}

// GroupsOperandValue ---------------------------------------------------------

export type GroupsOperandValue = Omit<IdsOperand, 'type'> & {
  type: OperandType.Groups
}

export function isGroupsOperandValueValid(value: GroupsOperandValue) {
  return value.ids.length > 0
}

// HandbooksOperandValue ------------------------------------------------------

export type HandbooksOperandValue = Omit<IdsOperand, 'type'> & {
  type: OperandType.Handbooks
}

export function isHandbooksOperandValueValid(value: HandbooksOperandValue) {
  return value.ids.length > 0
}

// ManagersOperandValue -------------------------------------------------------

export type ManagersOperandValue = Omit<ManagersOperand, 'type'> & {
  type: OperandType.Managers
}

export function isManagersOperandValueValid(value: ManagersOperandValue) {
  return value.ids.length > 0
}

// PeopleOperandValue ---------------------------------------------------------

export type PeopleOperandValue = Omit<IdsOperand, 'type'> & {
  type: OperandType.People
  field?: string
}

export function isPeopleOperandValueValid(value: PeopleOperandValue) {
  return value.ids.length > 0
}

// SavedSegmentsOperandValue -------------------------------------------------

export type SavedSegmentsOperandValue = Omit<IdsOperand, 'type'> & {
  type: OperandType.SavedSegments
}

export function isSavedSegmentsOperandValueValid(
  value: SavedSegmentsOperandValue,
) {
  return value.ids.length > 0
}

// SelectOperandValue ---------------------------------------------------------

export type SelectOperandValue = Omit<SelectOperand, 'type'> & {
  type: OperandType.Select
}

/**
 * Check to see if the values used for a select operand are valid.
 * The values are valid if they are not empty OR
 * if the values array is not present (e.g. placeholder for any value).
 *
 * @param values - The values to check.
 * @returns `true` if the values are valid, `false` otherwise.
 */
export function areSelectValuesValid<T>(values: T[]) {
  if (!values) return true // any value is valid
  return values.length > 0 // Must have at least one value
}

export function isSelectOperandValueValid(value: SelectOperandValue) {
  return areSelectValuesValid(value.values)
}

// SelectIdsOperandValue ---------------------------------------------------------

export type SelectIdsOperandValue = Omit<SelectIdsOperand, 'type'> & {
  type: OperandType.SelectIds
}

export function isSelectIdsOperandValueValid(value: SelectIdsOperandValue) {
  return areSelectValuesValid(value.values)
}

// TextOperandValue -----------------------------------------------------------

export type TextOperandValue = Omit<TextOperand, 'type'> & {
  type: OperandType.Text
}

export function isTextOperandValueValid(value: TextOperandValue): boolean {
  if (value.textCondition === TextOperandCondition.HasValue) return true
  return value.text.length > 0
}

// IdsOperandValue ---------------------------------------------------------------

export type IdsOperandValue =
  | GroupsOperandValue
  | HandbooksOperandValue
  | PeopleOperandValue
  | SavedSegmentsOperandValue

export type IdsOperandType = IdsOperandValue['type']

export const idsOperandTypes = [
  OperandType.Groups,
  OperandType.Handbooks,
  OperandType.People,
  OperandType.SavedSegments,
]

export function isIdsOperand(value: OperandValue): value is IdsOperandValue {
  return idsOperandTypes.includes(value.type)
}

function isSubsetOf(lhs: number[], rhs: number[]) {
  return lhs.every((id) => rhs.includes(id))
}

function isIdsOperandSubsetOf(lhs: IdsOperandValue, rhs: IdsOperandValue) {
  // All options must match
  const { ids: lhsIds, ...lhsRest } = lhs
  const { ids: rhsIds, ...rhsRest } = rhs
  if (JSON.stringify(lhsRest) !== JSON.stringify(rhsRest)) return false

  const { isEvery, isNot } = lhs
  // OR
  if (!isEvery && !isNot) {
    return isSubsetOf(lhsIds, rhsIds)
  }
  // NOT OR
  if (!isEvery && isNot) {
    return isSubsetOf(rhsIds, lhsIds)
  }
  // AND
  if (isEvery && !isNot) {
    return isSubsetOf(rhsIds, lhsIds)
  }
  // NOT AND
  if (isEvery && isNot) {
    return isSubsetOf(lhsIds, rhsIds)
  }
}

// OperandValue ---------------------------------------------------------------

export type OperandValue =
  | DateOperandValue
  | IdsOperandValue
  | ManagersOperandValue
  | SelectOperandValue
  | SelectIdsOperandValue
  | TextOperandValue

export function isOperandValueValid(value: OperandValue) {
  switch (value.type) {
    case 'date':
      return isDateOperandValueValid(value)
    case 'groups':
      return isGroupsOperandValueValid(value)
    case 'handbooks':
      return isHandbooksOperandValueValid(value)
    case 'managers':
      return isManagersOperandValueValid(value)
    case 'people':
      return isPeopleOperandValueValid(value)
    case 'savedSegments':
      return isSavedSegmentsOperandValueValid(value)
    case 'select':
      return isSelectOperandValueValid(value)
    case 'selectIds':
      return isSelectIdsOperandValueValid(value)
    case 'text':
      return isTextOperandValueValid(value)
  }
}

export function isOperandSubsetOf(lhs: OperandValue, rhs: OperandValue) {
  if (isIdsOperand(lhs) && isIdsOperand(rhs)) {
    return isIdsOperandSubsetOf(lhs, rhs)
  }

  return false
}
