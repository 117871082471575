import type { Document } from '@blissbook/application/graph'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'

export type DocumentStatus = {
  key: string
  icon: IconProp
  iconClassName: string
  label: string
}

const inReviewStatus: DocumentStatus = {
  key: 'inReview',
  icon: 'circle',
  iconClassName: 'tw-text-sunshine-700',
  label: 'In Review',
}

const publishedStatus: DocumentStatus = {
  key: 'published',
  icon: 'circle',
  iconClassName: 'tw-text-green-700',
  label: 'Published',
}

const draftStatus: DocumentStatus = {
  key: 'draft',
  icon: ['far', 'circle'],
  iconClassName: 'tw-text-gray-200',
  label: 'Draft',
}

export const documentStatuses = [publishedStatus, inReviewStatus, draftStatus]

export function getDocumentStatus(
  document: Pick<
    Document,
    'archivedAt' | 'lastPublishedVersionNumber' | 'reviewers'
  >,
): DocumentStatus {
  const { archivedAt } = document
  const isInReview = document.reviewers?.length > 0
  if (isInReview) return inReviewStatus

  const isPublished = document.lastPublishedVersionNumber > 0
  const isLive = !archivedAt && isPublished
  if (isLive) return publishedStatus

  return draftStatus
}
