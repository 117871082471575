export const ignoreErrors: string[] = [
  // Canvas errors
  'Invalid canvas size',

  // Websocket and network errors
  'websocket error',
  'timeout',
  'Failed to fetch',
  'REPLY_TIMEOUT',
  'Response not successful: Received status code 429',
  'Failed to fetch dynamically imported module',
  'Network Error',
  'AxiosError',
  'CKEditorError: cloud-services-internal-error: Not connected',

  // Generic errors and exceptions
  'Event captured as exception',
  'Non-Error promise rejection captured with value: undefined',
  'No error message',
  'ApolloError: Error message not found',

  // CKEditor specific errors
  'CKEditorError: collection-add-item-already-exists',
  'CKEditorError: cloud-services-internal-error: Not connected',

  // Miscellaneous and less informative errors
  'versions must NOT have more than 10 items',
  "null is not an object (evaluating 'e.backbone.chapters')",

  // Custom expected errors
  'Cannot Brand Organization',
]
