import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import type { DocumentStatus } from './DocumentStatus'

export type DocumentStatusIconProps = {
  className?: string
  value: DocumentStatus
}

export function DocumentStatusIcon({
  className,
  value,
}: DocumentStatusIconProps) {
  return (
    <FontAwesomeIcon
      className={cx(value.iconClassName, className)}
      fixedWidth
      icon={value.icon}
    />
  )
}
